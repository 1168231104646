import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadComponent: () => import('./pages/signup-options/signup-options.component').then((so) => so.SignupOptionsComponent)
    },
    {
        path: 'sign-up',
        loadComponent: () => import('./pages/sign-up/sign-up.component').then((sp) => sp.SignUpComponent)
    },
    {
        path: 'verify-email',
        loadComponent: () => import('./pages/verify-email/verify-email.component').then((ve) => ve.VerifyEmailComponent)
    },
    {
        path: 'verify-email-success',
        loadComponent: () =>
            import('./pages/verify-email-success/verify-email-success.component').then((ves) => ves.VerifyEmailSuccessComponent)
    },
    {
        path: 'edit-profile',
        loadComponent: () => import('./pages/edit-profile/edit-profile.component').then((ep) => ep.EditProfileComponent)
    },
    {
        path: 'view-profile',
        loadComponent: () => import('./pages/view-profile/view-profile.component').then((vp) => vp.ViewProfileComponent)
    }
];
